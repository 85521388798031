<template>
  <div>
    <b-tooltip
      target="warningSistemaIndisponivel"
      placement="bottom"
    >
      Informamos que nossa plataforma está homologada no e-CAC e é especializada em automatizar o acesso a esse ambiente.
    </b-tooltip>
    <font-awesome-icon
      id="warningSistemaIndisponivel"
      class="warning-indisponivel"
      icon="fa-solid fa-tower-broadcast"
      size="lg"
      fade
      @click="useModalSistemaIndisponivel"
    />
  </div>
</template>

<style lang="scss">

.warning-indisponivel {
  background-color: #E9F3EE;
  color: green;
  margin: .5rem;
  padding: 8px;
  border-radius: 50%;
}

</style>

<script>

/* eslint-disable no-param-reassign */
import Ripple from 'vue-ripple-directive'
import useModalSistemaIndisponivel from './shared/components/useModalSistemaIndisponivel'

export default {
  directives: {
    Ripple,
  },
  setup() {
    return {
      useModalSistemaIndisponivel,
    }
  },
}
</script>
