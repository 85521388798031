import Vue from 'vue'

export default function () {
  Vue.swal({
    title: '<div style="color:darkgreen;">Plataforma homologada pela Receita Federal</div>',
    html: `
        '<div style="color:green; font-size: 18px;">Bem-vindo!
          <br>
          Informamos que nossa plataforma está <b>homologada no e-CAC</b> e é 
          especializada em <b> automatizar</b> o acesso a esse ambiente. Isso significa 
          que você pode gerenciar suas obrigações fiscais de forma mais eficiente e segura, com 
          integração direta ao sistema da Receita Federal!
          <br><br><br>
          Atenciosamente Equipe Monitor Contábil
          </div>'
        `,
    icon: 'warning',
    iconColor: '#28a745',
    showConfirmButton: false,
    width: '30%',
    customClass: {
      popup: 'swal-m',
    },
  })
}
